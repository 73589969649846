import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieImageInteractive from "../../../../composants/layout/LayoutGalerieImageInteractive"
import Pannellum from "../../../../composants/image/Panellum"

import { obtenirPage } from "../../../../js/client-es/utils"
import fragmentsPages from "../../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    page: allPagesCompilationYaml(
      filter: { code: { eq: "desertDamaraland360EN" } }
    ) {
      ...FragmentPageYaml
    }
  }
`

export default function Damaraland360(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "page")

  const composantImageInteractive = (
    <Pannellum urlImageSource="/ressources/360/damaraland.jpg" />
  )

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <p>
          A 360° image taken in Namibia in Damaraland in 2019: the decor was
          worthy the Mars planet. Walk around the picture to discover a sky
          untouched by light pollution.
        </p>
        <p>
          We were in the middle of a small plain: the unobstructed view allowed
          us to see a huge portion of the celestial vault: the great Andromeda
          Galaxy, the Magellanic clouds, the Pleiades, the Galactic bulb, the
          North America nebula, the Zodiacal light, Jupiter, Saturn, almost
          everything is there. And this setting Milky Way, so low on the western
          horizon and so visible thanks to the purity of the sky.
        </p>
        <p>
          Alas, such places are endangered, even Chile is plagued by light
          pollution.
        </p>
        <div id="galerie-image-interactive-texte-exifs">
          28 frames panoramic, Nodal Ninja 3 panoramic head, Canon EOS 6D
          Astrodon modded, Samyang 24mm F1.4 set to F2.2, ISO 3200. Individual
          exposures of 20 seconds.
        </div>
      </section>
    </LayoutGalerieImageInteractive>
  )
}
